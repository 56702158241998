import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { CrmAuthRouteKey } from 'common-module/auth';
import {
  CrmLayoutLogoComponent,
  CrmLayoutV2FragmentDirective,
} from 'common-module/layout-v2';
import {
  CrmLocaleSelectorComponent,
  CrmTranslatePipe,
} from 'common-module/translate';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { AuthImageComponent } from '../auth/image/auth-image.component';
import { UserService } from '../user/user.service';

@Component({
  standalone: true,
  selector: 'app-nav-mobile',
  template: `
    <div class="nav-mobile" (click)="openNav()">
      <i nz-icon nzType="menu-fold" nzTheme="outline"></i>
    </div>
    <nz-drawer
      nzWrapClassName="nav-mobile-drawer"
      [nzVisible]="opened"
      (nzOnClose)="opened = false"
      nzWidth="100%"
    >
      <ng-container *nzDrawerContent>
        <crm-layout-logo></crm-layout-logo>
        <app-auth-image></app-auth-image>
        <div class="nav-mobile-footer">
          <div class="nav-mobile-locale">
            <div class="nav-mobile-locale-title">
              {{ 'generic.language' | crmTranslate }}
            </div>
            <crm-locale-selector></crm-locale-selector>
          </div>
          <button
            class="nav-mobile-logout"
            nz-button
            nzType="primary"
            nzSize="large"
            nzBlock
            (click)="logout()"
          >
            {{ 'crm.layout.signOut.title' | crmTranslate }}
          </button>
        </div>
      </ng-container>
    </nz-drawer>
  `,
  styles: [
    `
      .nav-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }

      .nav-mobile-footer {
        margin-top: auto;
      }

      .nav-mobile-locale {
        display: flex;

        &-title {
          flex: 1;
        }
      }

      .nav-mobile-logout {
        margin-top: 24px;
        text-transform: uppercase;
      }
    `,
  ],
  imports: [
    NzIconModule,
    NzDrawerModule,
    CrmLayoutLogoComponent,
    AuthImageComponent,
    CrmLocaleSelectorComponent,
    CrmTranslatePipe,
    NzButtonModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavMobileComponent extends CrmLayoutV2FragmentDirective<void> {
  opened = false;

  protected readonly router = inject(Router);
  protected readonly userService = inject(UserService);

  openNav() {
    this.opened = true;
  }

  logout() {
    this.userService.logout().subscribe(() => {
      this.router.navigate(['auth', CrmAuthRouteKey.login]);
    });
  }
}
