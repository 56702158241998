import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CrmAppComponent } from 'common-module/core';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CrmAppComponent],
})
export class AppComponent {}
