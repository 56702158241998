import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { crmCreateModalFn$ } from 'common-module/modal';
import { EMPTY, switchMap, tap } from 'rxjs';

import { openForgottenPasswordFn } from '../forgotten-password/forgotten-password.factory';

import { CheckEmailComponent } from './check-email/check-email.component';
import { ExistsComponent } from './exists/exists.component';
import { ExistsData } from './exists/exists.data';
import { RegisterComponent } from './register/register.component';
import { openSuccessFn } from './success/success.factory';

@Injectable()
export class RegistrationService {
  private router = inject(Router);
  private forgottenPassword = openForgottenPasswordFn();
  private success = openSuccessFn();
  private createModal = crmCreateModalFn$();

  openCheckEmailModal() {
    this.createModal(CheckEmailComponent)
      .pipe(
        switchMap(({ email, state }) => {
          switch (state) {
            case 'new':
              return this.openRegisterModal({ email });
            case 'registered':
              return this.openExistsModal({ email });
          }
        }),
      )
      .subscribe();
  }

  openRegisterModal(data: { email: string }) {
    return this.createModal(RegisterComponent, data).pipe(
      switchMap(() =>
        this.success('auth.registration.success.title', {
          message: 'auth.registration.success.message',
          text: 'auth.registration.success.text',
        }).pipe(
          tap(() =>
            this.router.navigate(['/auth/login'], {
              queryParams: { alert: 'registered' },
              replaceUrl: true,
            }),
          ),
        ),
      ),
    );
  }

  openExistsModal(data: ExistsData) {
    return this.createModal(ExistsComponent, data).pipe(
      switchMap(({ exists }) => {
        if (exists) {
          return this.openForgottenPassword(data.email);
        }

        return EMPTY;
      }),
    );
  }

  openForgottenPassword(email?: string) {
    return this.forgottenPassword(email).pipe(
      switchMap(({ success }) => {
        if (success) {
          return this.success('auth.forgottenPassword.title', {
            message: 'auth.forgottenPassword.successTitle',
            text: 'auth.forgottenPassword.successText',
          }).pipe(
            tap(() =>
              this.router.navigate(['/auth/login'], {
                queryParams: { alert: 'reset-password' },
                replaceUrl: true,
              }),
            ),
          );
        }

        return EMPTY;
      }),
    );
  }
}
