import { Injectable } from '@angular/core';
import { CrmOption } from 'common-module/common';
import { CrmDictionary } from 'common-module/core/types';
import {
  CrmEndpoint,
  CrmEndpointDecorator,
  CrmEndpointListResponse,
} from 'common-module/endpoint';
import { crmSafeListByIDs } from 'common-module/list';
import { catchError, map, Observable, of, switchMap } from 'rxjs';

import { MetaModel } from './meta.model';
import { MetaType } from './meta.type';

export interface MetaResult {
  raw: MetaModel;
  select: CrmOption<string, MetaModel>;
}

@Injectable({ providedIn: 'root' })
export class MetaApiService {
  @CrmEndpointDecorator({
    configName: 'crm',
    endpointName: 'meta',
  })
  protected readonly endpoint!: CrmEndpoint<MetaModel>;

  list(type: MetaType, params?: CrmDictionary) {
    return this.endpoint.request<CrmEndpointListResponse<MetaModel>>(
      'GET',
      type,
      { params },
    );
  }

  listData(type: MetaType, params?: CrmDictionary) {
    return this.list(type, params).pipe(map((resp) => resp.data));
  }

  listAll<Result extends 'raw' | 'select', Params>(
    type: MetaType,
    options: { result: Result; label?: 'value' | 'name' },
    params?: Params,
  ): Observable<MetaResult[Result][]> {
    const LIST_ALL_LIMIT = 20;

    return this.list(type, { ...params, $limit: LIST_ALL_LIMIT }).pipe(
      switchMap((response) => {
        if (response.total > LIST_ALL_LIMIT) {
          return this.list(type, { ...params, $limit: response.total });
        }

        return of(response);
      }),
      map<CrmEndpointListResponse<MetaModel>, MetaResult[Result][]>(
        (response) => {
          switch (options?.result) {
            case 'select':
              return response.data
                .filter(({ value }) => !!value)
                .map((data) => ({
                  label: data[options.label ?? 'value'] ?? data.value,
                  value: data.value,
                  data,
                })) as MetaResult[Result][];
            case 'raw':
              return response.data as MetaResult[Result][];
            default:
              return [];
          }
        },
      ),
      catchError(() => of([])),
    );
  }

  listSpecies<Result extends 'raw' | 'select', Params>(
    options: { result: Result },
    params?: Params,
  ): Observable<MetaResult[Result][]> {
    return this.listAll(MetaType.species, options, params);
  }

  listCountries<Result extends 'raw' | 'select', Params>(
    options: { result: Result },
    params?: Params,
  ): Observable<MetaResult[Result][]> {
    return this.listAll(
      MetaType.countries,
      { ...options, label: 'name' },
      params,
    );
  }

  listBreeds<Result extends 'raw' | 'select', Params>(
    options: { result: Result },
    params?: Params,
  ): Observable<MetaResult[Result][]> {
    return this.listAll(MetaType.breeds, options, params);
  }

  listColors<Result extends 'raw' | 'select', Params>(
    options: { result: Result },
    params?: Params,
  ): Observable<MetaResult[Result][]> {
    return this.listAll(MetaType.colors, options, params);
  }

  listAppointmentReasons<Result extends 'raw' | 'select', Params>(
    options: { result: Result },
    params?: Params,
  ): Observable<MetaResult[Result][]> {
    return this.listAll(MetaType.appointmentReasons, options, params);
  }

  listAppointmentReasonsByIds(ids: string[]) {
    return crmSafeListByIDs(
      (params) => this.listData(MetaType.appointmentReasons, params),
      ids,
      20,
    );
  }
}
