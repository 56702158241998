import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CrmAuthModule } from 'common-module/auth';
import { CrmAppConfig } from 'common-module/core';
import { CrmEndpointModule } from 'common-module/endpoint';
import { CrmMessageModule } from 'common-module/message';
import { CrmModalModule } from 'common-module/modal';
import { CrmTranslateModule } from 'common-module/translate';
import { CrmUserService } from 'common-module/user';
import { Settings } from 'luxon';

import { UserService } from '~/crm/user/user.service';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { appRoutes } from './app/app.routes';
import { authConfig } from './app/config/auth.config';
import { translateConfig } from './app/config/translate.config';
import {
  endpointModuleConfiguration,
  environment,
} from './environments/environment';

Settings.throwOnInvalid = true;
declare module 'luxon' {
  interface TSSettings {
    throwOnInvalid: true;
  }
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom([
      BrowserAnimationsModule,
      RouterModule.forRoot(appRoutes),

      CrmAuthModule.forRoot(authConfig),
      CrmTranslateModule.forRoot(translateConfig),
      CrmEndpointModule.forRoot(endpointModuleConfiguration),
      CrmModalModule.forRoot(),
      CrmMessageModule.forRoot(),
    ]),

    { provide: CrmAppConfig, useValue: appConfig },
    { provide: CrmUserService, useClass: UserService },
  ],
});
