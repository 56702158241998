import { inject, Injectable } from '@angular/core';
import { CrmActionButton } from 'common-module/buttons';
import { CrmMessageService } from 'common-module/message';
import { CrmModalFormProvider } from 'common-module/modal';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { UserService } from '../../user/user.service';

import { ForgottenPasswordFormProvider } from './forgotten-password-form.provider';

/**
 * Provider for forgotten password modal
 */
@Injectable()
export class ForgottenPasswordProvider extends CrmModalFormProvider<
  { success: true },
  ForgottenPasswordFormProvider
> {
  override formProvider = inject(ForgottenPasswordFormProvider);

  protected override saveTitle = 'generic.continue';
  protected override cancelTitle = 'generic.cancel';
  protected override isDisabled$ = new BehaviorSubject(false);

  private user = inject(UserService);
  private message = inject(CrmMessageService);

  protected override makeRequest(): Observable<{ success: true }> {
    return this.user
      .resetPassword({ email: this.formProvider.formRawValue.email })
      .pipe(map(() => ({ success: true })));
  }

  protected override mapIsDisabledAction(): boolean {
    return false;
  }

  protected override saveActionConfig(
    override?: Partial<CrmActionButton>,
  ): CrmActionButton {
    return super.saveActionConfig(override);
  }

  protected override onError() {
    this.message.error('auth.forgottenPassword.error');
  }
}
