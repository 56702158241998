import { environment } from '~/environments/environment';

export const getCurrentDomain = () => {
  if (environment.domain) {
    return environment.domain;
  }

  if (location.hostname.startsWith('client.')) {
    return location.hostname.replace('client.', '');
  }

  return location.hostname;
};
