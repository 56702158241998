import { Injectable } from '@angular/core';
import {
  crmEmailValidator,
  crmFormEmailValidator,
  crmFormRequiredValidator,
} from 'common-module/form';
import { CrmFormFragment } from 'common-module/form/fragments';
import { crmGetFormInput } from 'common-module/form/inputs';
import { Observable, of } from 'rxjs';

import { FormProvider } from '../../../form/form.provider';

import { CheckEmailFormData } from './check-email-form.data';

@Injectable()
export class CheckEmailFormProvider extends FormProvider<CheckEmailFormData> {
  protected override getFormFragments(): Observable<CrmFormFragment[]> {
    return of([
      crmGetFormInput({
        name: 'email',
        config: {
          label: 'generic.email',
          validators: [
            crmFormRequiredValidator(),
            crmFormEmailValidator({ validator: crmEmailValidator() }),
          ],
        },
      }),
    ]);
  }
}
