import { Injectable } from '@angular/core';
import {
  crmEmailValidator,
  CrmFormConfigOptions,
  crmFormEmailValidator,
  crmFormRequiredValidator,
} from 'common-module/form';
import { CrmFormFragment } from 'common-module/form/fragments';
import { crmGetFormInput } from 'common-module/form/inputs';
import { Observable, of } from 'rxjs';

import { FormProvider } from '../../form/form.provider';

import { ForgottenPasswordMessageComponent } from './forgotten-password-message.component';

/**
 * Form provider for forgotten password modal
 */
@Injectable()
export class ForgottenPasswordFormProvider extends FormProvider<{
  email?: string;
}> {
  protected override getFormFragments(
    options: CrmFormConfigOptions<{ email: string }>,
  ): Observable<CrmFormFragment[]> {
    return of([
      {
        type: 'design',
        name: 'msg',
        content: ForgottenPasswordMessageComponent,
      },
      crmGetFormInput({
        name: 'email',
        config: {
          label: 'generic.email',
          defaultValue: options.data.email,
          validators: [
            crmFormRequiredValidator(),
            crmFormEmailValidator({ validator: crmEmailValidator() }),
          ],
        },
      }),
    ]);
  }
}
