import { FormGroup } from '@angular/forms';
import { CrmForm, CrmFormProvider } from 'common-module/form';
import { Subject } from 'rxjs';

import { DataFormGroup } from '~/shared/types/data-form-group';

export type Form<FormData> = Omit<CrmForm<FormData>, 'form'> & {
  form: FormGroup<DataFormGroup<FormData>>;
};

export class FormProvider<Input, FormData = Input> extends CrmFormProvider<
  Input,
  FormData
> {
  override crmForm!: Form<FormData>;
  override initialized$!: Subject<Form<FormData>>;

  override get form(): Form<FormData>['form'] {
    return super.form;
  }

  override get formValue(): Form<FormData>['form']['value'] {
    return super.formValue;
  }

  override get formRawValue(): ReturnType<
    Form<FormData>['form']['getRawValue']
  > {
    return super.formRawValue;
  }

  override formInitialized(form: Form<FormData>) {
    super.formInitialized(form);
  }
}
