import { assertInInjectionContext } from '@angular/core';
import { crmCreateModalFormFn$ } from 'common-module/modal';

import { ForgottenPasswordFormProvider } from './forgotten-password-form.provider';
import { ForgottenPasswordProvider } from './forgotten-password.provider';

/**
 * Open forgotten password factory function
 */
export const openForgottenPasswordFn = () => {
  assertInInjectionContext(() => {});

  const modal = crmCreateModalFormFn$();

  return (email?: string) =>
    modal({
      modalProvider: ForgottenPasswordProvider,
      formProvider: ForgottenPasswordFormProvider,
      data: { email },
      title: 'auth.forgottenPassword.title',
    });
};
