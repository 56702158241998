import { Component, inject, signal } from '@angular/core';
import { CrmFormComponent, crmValidateForm } from 'common-module/form';
import { CrmMessageService } from 'common-module/message';
import { CrmModalRefDirective } from 'common-module/modal';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzModalModule } from 'ng-zorro-antd/modal';

import { ModalActionsComponent } from '~/shared/components/modal-actions/modal-actions.component';

import { CheckEmailFormProvider } from './check-email-form.provider';
import { CheckEmailService } from './check-email.service';

export type CheckEmailResultState = 'new' | 'registered';

export interface CheckEmailResult {
  state: CheckEmailResultState;
  email: string;
}

@Component({
  standalone: true,
  selector: 'app-register-check-email',
  template: `
    <ng-container *nzModalTitle>
      {{ 'auth.registration.checkEmail.title' | crmTranslate }}
    </ng-container>
    <div class="fw-500 mb-2">
      {{ 'auth.registration.checkEmail.message' | crmTranslate }}
    </div>

    <crm-form [provider]="provider" (formSubmitted)="handleSubmit()"></crm-form>

    <ng-container *nzModalFooter>
      <app-modal-actions
        [isLoading]="isLoading()"
        [submit]="handleSubmit"
        [dismiss]="dismiss"
        [submitMessage]="'generic.continue'"
      ></app-modal-actions>
    </ng-container>
  `,
  imports: [
    NzModalModule,
    CrmTranslatePipe,
    ModalActionsComponent,
    CrmFormComponent,
  ],
  providers: [CheckEmailService, CheckEmailFormProvider],
})
export class CheckEmailComponent extends CrmModalRefDirective<
  CheckEmailResult,
  void
> {
  isLoading = signal(false);

  protected provider = inject(CheckEmailFormProvider);
  protected service = inject(CheckEmailService);
  protected messageService = inject(CrmMessageService);

  constructor() {
    super();
    this.modalRef.updateConfig({ nzMaskClosable: false });
  }

  handleSubmit = () => {
    if (this.provider.form.invalid) {
      crmValidateForm(this.provider.form);
      return;
    }

    this.isLoading.set(true);

    const { email } = this.provider.formRawValue;

    this.service.isEmailAvailable(email).subscribe({
      next: (available) => {
        this.submit({ state: available ? 'new' : 'registered', email });
      },
      error: () => {
        this.messageService.error('generic.somethingWentWrong');
        this.isLoading.set(false);
      },
    });
  };

  override dismiss = () => {
    super.dismiss();
  };
}
