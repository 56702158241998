import { CrmAppConfiguration } from 'common-module/core';
import {
  CrmLayoutLocaleComponent,
  CrmLayoutLogoComponent,
  CrmLayoutSignOutComponent,
  CrmLayoutV2Config,
} from 'common-module/layout-v2';

import { LogoProvider } from '~/crm/layout/logo.provider';
import { NavMobileComponent } from '~/crm/layout/nav-mobile.component';
import { environment } from '~/environments/environment';

export const appConfig: CrmAppConfiguration = {
  environment,
  modules: {
    layoutV2: {
      siderLeft: undefined,
      header: {
        fragments: [
          {
            key: 'logo',
            content: CrmLayoutLogoComponent,
            contentContext: {
              providerToken: LogoProvider,
            } as Partial<CrmLayoutLogoComponent>,
          },
          {
            key: 'locale',
            content: CrmLayoutLocaleComponent,
          },
          {
            key: 'sign-out',
            content: CrmLayoutSignOutComponent,
          },
          {
            key: 'nav-mobile',
            content: NavMobileComponent,
          },
        ],
      },
    } as CrmLayoutV2Config,
  },
};
