import { CrmOption } from 'common-module/common';
import { crmFormRequiredValidator } from 'common-module/form';
import { CrmFormFragment } from 'common-module/form/fragments';
import { crmGetFormInput, crmGetFormSelect } from 'common-module/form/inputs';

import { defaultLayout } from '../layout/default-layout';

export interface UserFormCompanyData {
  name: string;
  id: string;
  vat: string;
  tax: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
}

export const getUserFormCompanyConfig = (inputs: {
  countries: CrmOption[];
}): CrmFormFragment[] => {
  return [
    crmGetFormInput({
      name: 'name',
      config: {
        label: 'shared.user.form.companyName',
        validators: [crmFormRequiredValidator()],
      },
      layout: defaultLayout,
    }),
    crmGetFormInput({
      name: 'id',
      config: {
        label: 'shared.user.form.companyId',
        validators: [crmFormRequiredValidator()],
      },
      layout: defaultLayout,
    }),
    crmGetFormInput({
      name: 'tax',
      config: {
        label: 'shared.user.form.companyTax',
        validators: [crmFormRequiredValidator()],
      },
      layout: defaultLayout,
    }),
    crmGetFormInput({
      name: 'vat',
      config: {
        label: 'shared.user.form.companyVat',
        validators: [crmFormRequiredValidator()],
      },
      layout: defaultLayout,
    }),
    crmGetFormInput({
      name: 'address',
      config: {
        label: 'shared.user.form.address',
        validators: [crmFormRequiredValidator()],
      },
    }),
    crmGetFormInput({
      name: 'city',
      config: {
        label: 'shared.user.form.city',
        validators: [crmFormRequiredValidator()],
      },
      layout: defaultLayout,
    }),
    crmGetFormInput({
      name: 'postalCode',
      config: {
        label: 'shared.user.form.postalCode',
        validators: [crmFormRequiredValidator()],
      },
      layout: defaultLayout,
    }),
    crmGetFormSelect({
      name: 'country',
      config: {
        label: 'shared.user.form.country',
        validators: [crmFormRequiredValidator()],
      },
      options: { options: inputs.countries },
    }),
  ];
};
