import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { CrmTranslateDirective } from 'common-module/translate';
import { NzIconDirective } from 'ng-zorro-antd/icon';

/**
 * Component with information for forgotten password modal
 */
@Component({
  standalone: true,
  selector: 'app-fp-message',
  template: `
    <span nz-icon nzType="icons:lock"></span>
    <ng-container *crmTranslate="let tm">
      <div class="crm-register-info-title">
        {{ tm('auth.forgottenPassword.message') }}
      </div>
      <div class="crm-register-info-text">
        {{ tm('auth.forgottenPassword.text') }}
      </div>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CrmTranslateDirective, NzIconDirective],
})
export class ForgottenPasswordMessageComponent {
  @HostBinding('class')
  protected readonly hostClass = 'forgotten-password--message';
}
