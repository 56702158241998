import { Component } from '@angular/core';
import { CrmFormGroupComponent } from 'common-module/form/fragments';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzDividerModule } from 'ng-zorro-antd/divider';

@Component({
  standalone: true,
  selector: 'app-register-company',
  template: `
    <crm-form-group
      [form]="form"
      [name]="name"
      [config]="config"
    ></crm-form-group>
    <nz-divider
      nzOrientation="left"
      [nzText]="'shared.user.form.contactPerson' | crmTranslate"
    ></nz-divider>
  `,
  imports: [CrmFormGroupComponent, NzDividerModule, CrmTranslatePipe],
})
export class RegisterCompanyComponent extends CrmFormGroupComponent {}
