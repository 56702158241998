import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CrmFormDataInputValidator } from 'common-module/form/fragments';
import { parsePhoneNumber } from 'libphonenumber-js';

export const phoneValidator = (
  control: AbstractControl<string>,
): ValidationErrors | null => {
  const value = control.value;

  if (!value?.length) {
    return null;
  }

  if (!value.match(/^\+?\d+$/)) {
    return { phone: true };
  }

  try {
    const phoneNumber = parsePhoneNumber(value);
    return !phoneNumber.isValid() ? { phone: true } : null;
  } catch {
    return { phone: true };
  }
};

export const formPhoneValidator: CrmFormDataInputValidator<ValidatorFn> = {
  name: 'phone',
  error: 'crm.validator.error.phone',
  validator: phoneValidator,
};
