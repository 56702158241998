// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { CrmAppEnvironment } from 'common-module/core';
import { CrmEndpointModuleConfig } from 'common-module/endpoint';

import { APP_PROJECT_NAME, APP_VERSION } from './variables';

export const environment: CrmAppEnvironment = {
  production: false,
  env: 'development',
  version: APP_VERSION,
  projectName: APP_PROJECT_NAME,
};

export const CRM_URL = 'https://client-api.docut.touch4dev.net';

export const endpointModuleConfiguration: CrmEndpointModuleConfig = {
  endpoints: [
    {
      name: 'crm',
      baseUrl: CRM_URL,
      withCredentials: true,
      httpHeaders: {
        Authorization: 'Basic ZG9jdXQ6VDREY2w1YWRBUTRKdXpmZ3pQcnE=',
      },
    },
  ],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
