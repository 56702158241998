import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { UserService } from '../../../user/user.service';

@Injectable()
export class CheckEmailService {
  protected readonly userService = inject(UserService);

  isEmailAvailable(email: string): Observable<boolean> {
    return this.userService
      .checkEmail({ email })
      .pipe(map((response) => response.available));
  }
}
