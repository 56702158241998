import { CrmAuthConfig, CrmAuthRouteKey } from 'common-module/auth';

import { AuthImageComponent } from '~/crm/auth/image/auth-image.component';
import { LoginComponent } from '~/crm/auth/login/login.component';
import { ResetPasswordProvider } from '~/crm/auth/reset-password/reset-password.provider';

export const authConfig: CrmAuthConfig = {
  components: {
    login: { content: { content: LoginComponent } },
    resetPassword: {
      messages: { showText: true },
      providers: { resetPassword: ResetPasswordProvider },
    },
    wrapper: {
      imagePosition: 'right',
      layout: 'image',
      imageContent: { content: AuthImageComponent },
      logoSrc: '/assets/images/zook-logo.svg',
    },
    success: { wrapper: { logoSrc: '/assets/images/zook-logo.svg' } },
  },
  routeGuard: { disabledRoutes: [CrmAuthRouteKey.forgottenPassword] },
  interceptor: {
    interceptHttpCode: [401, 403],
    ignoredInterceptPaths: ['/auth/.'],
  },
};
