import { inject, Injectable } from '@angular/core';
import { CrmFormConfigOptions } from 'common-module/form';
import { CrmFormFragment, crmGetFormGroup } from 'common-module/form/fragments';
import { crmGetFormCheckbox } from 'common-module/form/inputs';
import { map, Observable, of, startWith } from 'rxjs';

import { MetaApiService } from '~/api/meta/meta-api.service';
import { FormProvider } from '~/crm/form/form.provider';
import { getUserFormCompanyConfig } from '~/shared/form/user/user-form-company';
import { getUserFormPersonConfig } from '~/shared/form/user/user-form-person';
import { getCurrentDomain } from '~/shared/utils/common/get-current-domain';

import { RegisterCompanyComponent } from './register-company.component';
import {
  RegisterFormData,
  RegisterFormGroup,
  RegisterFormInputData,
} from './register-form.data';

@Injectable()
export class RegisterFormProvider extends FormProvider<
  RegisterFormInputData,
  RegisterFormData
> {
  private meta = inject(MetaApiService);

  private countries$ = this.meta.listCountries(
    { result: 'select' },
    { domain: getCurrentDomain() },
  );

  protected override getFormFragments(): Observable<CrmFormFragment[]> {
    const isCompanyVisible$ = (form: RegisterFormGroup) => {
      const isCompany = form.controls.registerAsCompany;
      return isCompany.valueChanges.pipe(startWith(isCompany.value));
    };

    return this.countries$.pipe(
      map((countries) => [
        crmGetFormCheckbox({
          name: 'registerAsCompany',
          options: { label: `auth.registration.register.asCompany` },
        }),
        {
          type: 'group',
          name: 'company',
          content: RegisterCompanyComponent,
          children: getUserFormCompanyConfig({ countries }),
          visible$: isCompanyVisible$,
        },
        crmGetFormGroup({
          name: 'person',
          children: getUserFormPersonConfig({
            disabledFields: { email: true },
            countries,
          }),
        }),
      ]),
    );
  }

  protected override getFormData(
    options: CrmFormConfigOptions<RegisterFormInputData>,
  ): Observable<RegisterFormData> {
    const { data } = options;

    return of({
      registerAsCompany: false,
      company: {},
      person: { email: data.email },
    });
  }
}
