import { Injectable } from '@angular/core';
import { CrmResetPasswordProviderImpl } from 'common-module/auth';

@Injectable()
export class ResetPasswordProvider extends CrmResetPasswordProviderImpl {
  protected override handleSuccess() {
    this.router.navigate(['/auth/login'], {
      queryParams: { alert: 'password-changed' },
    });
  }
}
