import { Component, HostListener, inject } from '@angular/core';
import { crmKillEvent } from 'common-module/core';
import { CrmFormComponent, crmValidateForm } from 'common-module/form';
import { CrmMessageService } from 'common-module/message';
import { CrmModalRefDirective } from 'common-module/modal';
import { CrmTranslatePipe } from 'common-module/translate';
import { CrmUser } from 'common-module/user';
import { NzModalModule } from 'ng-zorro-antd/modal';

import { ModalActionsComponent } from '~/shared/components/modal-actions/modal-actions.component';

import { RegisterFormInputData } from './register-form.data';
import { RegisterFormProvider } from './register-form.provider';
import { RegisterService } from './register.service';

@Component({
  standalone: true,
  selector: 'app-register',
  template: `
    <ng-container *nzModalTitle>
      {{ 'auth.registration.register.title' | crmTranslate }}
    </ng-container>

    <crm-form [provider]="formProvider" [data]="data"></crm-form>

    <ng-container *nzModalFooter>
      <app-modal-actions
        [submitMessage]="'auth.actions.register'"
        [isLoading]="isLoading"
        [submit]="handleSubmit"
        [dismiss]="dismiss"
      ></app-modal-actions>
    </ng-container>
  `,
  imports: [
    NzModalModule,
    CrmTranslatePipe,
    CrmFormComponent,
    ModalActionsComponent,
  ],
  providers: [RegisterFormProvider, RegisterService],
})
export class RegisterComponent extends CrmModalRefDirective<
  CrmUser,
  RegisterFormInputData
> {
  override data!: RegisterFormInputData;

  isLoading = false;

  readonly formProvider = inject(RegisterFormProvider);
  protected readonly registerService = inject(RegisterService);
  protected readonly messageService = inject(CrmMessageService);

  constructor() {
    super();
    this.modalRef.updateConfig({
      nzMaskClosable: false,
      nzWidth: '720px',
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyDown(ev: KeyboardEvent) {
    if (ev.key !== 'Enter') {
      return;
    }

    crmKillEvent(ev);
    this.handleSubmit();
  }

  handleSubmit = () => {
    crmValidateForm(this.formProvider.form);
    if (this.formProvider.form.invalid) {
      return;
    }

    this.isLoading = true;

    this.registerService.register(this.formProvider.formRawValue).subscribe({
      next: (user) => {
        this.submit(user);
      },
      error: () => {
        this.messageService.error('auth.registration.register.failed');
        this.isLoading = false;
      },
    });
  };

  override dismiss = () => {
    super.dismiss();
  };
}
