@if (clinic(); as clinic) {
  @if (clinic.logoUrl; as logo) {
    <div class="login-logo">
      <img [src]="logo" alt="login logo" />
    </div>
  } @else {
    <div class="login-title">{{ clinic.profile.name }}</div>
  }
}

<p class="login-text">{{ 'auth.login.message' | crmTranslate }}</p>

@if (alert(); as alert) {
  <nz-alert
    class="login-error"
    [nzType]="$any(alert.type)"
    [nzDescription]="alert.message | crmTranslate"
  ></nz-alert>
}

<crm-form [provider]="formProvider" (formSubmitted)="handleSubmit()"></crm-form>

<div class="login-forgotten-password" (click)="handleForgottenPassword()">
  <i nz-icon nzType="question-circle" nzTheme="outline" class="mr-1"></i>
  <span>{{ 'auth.actions.forgottenPassword' | crmTranslate }}</span>
</div>

<button
  nz-button
  nzType="primary"
  nzBlock
  nzSize="large"
  [nzLoading]="isLoading()"
  (click)="handleSubmit()"
>
  {{ 'auth.login.submit' | crmTranslate | uppercase }}
</button>

<p class="login-no-account">
  {{ 'auth.login.noAccount' | crmTranslate }}
</p>

<button
  nz-button
  nzType="default"
  nzBlock
  nzSize="large"
  [disabled]="isLoading()"
  (click)="handleRegister()"
>
  {{ 'auth.actions.register' | crmTranslate | uppercase }}
</button>
