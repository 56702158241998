import { CrmOption } from 'common-module/common';
import {
  crmEmailValidator,
  crmFormEmailValidator,
  crmFormRequiredValidator,
} from 'common-module/form';
import { CrmFormFragment } from 'common-module/form/fragments';
import { crmGetFormInput, crmGetFormSelect } from 'common-module/form/inputs';

import { formPhoneValidator } from '../../utils/validators/phone-validator';
import { defaultLayout } from '../layout/default-layout';

export interface UserFormPersonData {
  titleBefore: string;
  firstName: string;
  lastName: string;
  titleAfter: string;
  email: string;
  phone: string;
  title: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
}

export const getUserFormPersonConfig = (inputs: {
  disabledFields?: { email?: boolean };
  countries: CrmOption[];
}): CrmFormFragment[] => {
  return [
    crmGetFormInput({
      name: 'titleBefore',
      config: { label: 'shared.user.form.titleBefore' },
      layout: { span: { md: 8, xs: 24 } },
    }),
    crmGetFormInput({
      name: 'firstName',
      config: {
        label: 'shared.user.form.firstName',
        validators: [crmFormRequiredValidator()],
      },
      layout: { span: { md: 8, xs: 24 } },
    }),
    crmGetFormInput({
      name: 'lastName',
      config: {
        label: 'shared.user.form.lastName',
        validators: [crmFormRequiredValidator()],
      },
      layout: { span: { md: 8, xs: 24 } },
    }),
    crmGetFormInput({
      name: 'titleAfter',
      config: { label: 'shared.user.form.titleAfter' },
      layout: { span: { md: 8, xs: 24 } },
    }),
    { name: 'spacer-1', type: 'design', layout: { span: { md: 16, xs: 24 } } },
    crmGetFormInput({
      name: 'email',
      config: {
        label: 'shared.user.form.email',
        disabled: inputs?.disabledFields?.email,
        validators: [
          crmFormRequiredValidator(),
          crmFormEmailValidator({ validator: crmEmailValidator() }),
          {
            name: 'taken',
            error: 'error.emailTaken',
            validator: () => null,
          },
        ],
      },
      layout: defaultLayout,
    }),
    crmGetFormInput({
      name: 'phone',
      config: {
        label: 'shared.user.form.phone',
        validators: [formPhoneValidator],
      },
      layout: defaultLayout,
    }),
    crmGetFormSelect({
      name: 'title',
      config: { label: 'shared.user.form.titlePerson' },
      options: {
        options: [
          {
            value: 'unspecified',
            label: 'shared.user.gender.unspecified',
          },
          { value: 'male', label: 'shared.user.gender.male' },
          {
            value: 'female',
            label: 'shared.user.gender.female',
          },
        ],
      },
      layout: { span: { md: 8, xs: 24 } },
    }),
    crmGetFormInput({
      name: 'address',
      config: {
        label: 'shared.user.form.address',
        validators: [crmFormRequiredValidator()],
      },
    }),
    crmGetFormInput({
      name: 'city',
      config: {
        label: 'shared.user.form.city',
        validators: [crmFormRequiredValidator()],
      },
      layout: defaultLayout,
    }),
    crmGetFormInput({
      name: 'postalCode',
      config: {
        label: 'shared.user.form.postalCode',
        validators: [crmFormRequiredValidator()],
      },
      layout: defaultLayout,
    }),
    crmGetFormSelect({
      name: 'country',
      config: {
        label: 'shared.user.form.country',
        validators: [crmFormRequiredValidator()],
      },
      options: { options: inputs.countries },
      layout: defaultLayout,
    }),
  ];
};
