import { UpperCasePipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { CrmBaseLoginComponent } from 'common-module/auth';
import { CrmFormComponent } from 'common-module/form';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { map, merge, Subject } from 'rxjs';

import { ClinicsApiService } from '~/api/clinics/clinics-api.service';

import { RegistrationService } from '../registration/registration.service';

@Component({
  standalone: true,
  selector: 'app-login',
  templateUrl: 'login.component.html',
  imports: [
    CrmTranslatePipe,
    CrmFormComponent,
    NzIconModule,
    NzButtonModule,
    UpperCasePipe,
    NzAlertModule,
  ],
  providers: [RegistrationService],
})
export class LoginComponent extends CrmBaseLoginComponent {
  protected clinic$ = inject(ClinicsApiService).getProfileAndLogo();
  protected clinic = toSignal(this.clinic$);

  protected error$ = new Subject<'notFoundCombination' | 'notActivated'>();
  protected alertFromError$ = this.error$.pipe(
    map((type) => ({
      message: 'auth.login.errorMessage.' + type,
      type: 'error',
    })),
  );

  protected alertFromQueryParams$ = inject(ActivatedRoute).queryParams.pipe(
    map((params) => {
      switch (params['alert']) {
        case 'registered':
          return {
            message: 'auth.registration.success.text',
            type: 'success',
          };
        case 'reset-password':
          return {
            message: 'auth.forgottenPassword.successText',
            type: 'success',
          };
        case 'password-changed':
          return {
            message: 'auth.resetPassword.successText',
            type: 'success',
          };
        default:
          return undefined;
      }
    }),
  );

  protected alert$ = merge(this.alertFromQueryParams$, this.alertFromError$);
  protected alert = toSignal(this.alert$);

  private register = inject(RegistrationService);

  override handleForgottenPassword() {
    if (this.isLoading()) {
      return;
    }

    this.register
      .openForgottenPassword(this.formProvider.formRawValue.email)
      .subscribe();
  }

  handleRegister() {
    this.register.openCheckEmailModal();
  }

  protected override handleError() {
    this.error$.next('notFoundCombination');
  }
}
