import { inject, Injectable } from '@angular/core';
import { CrmUser } from 'common-module/user';
import { Observable } from 'rxjs';

import { RegisterUserDto } from '~/api/user/register-user-dto';

import { UserService } from '../../../user/user.service';

import { RegisterFormProvider } from './register-form.provider';

@Injectable()
export class RegisterService {
  protected readonly userService = inject(UserService);

  register(data: RegisterFormProvider['formRawValue']): Observable<CrmUser> {
    const { registerAsCompany, company, person } = data;

    const body: RegisterUserDto = {
      firstName: person.firstName,
      lastName: person.lastName,
      title: {
        before: person.titleBefore,
        after: person.titleAfter,
      },
      gender: person.title,
      email: person.email,
      phone: person.phone,
      address: person.address,
      city: person.city,
      country: person.country,
      postalCode: person.postalCode,
    };

    if (registerAsCompany) {
      body.company = {
        name: company.name,
        id: company.id,
        vat: company.vat,
        address: company.address,
        city: company.city,
        postalCode: company.postalCode,
        country: company.country,
      };
    }

    return this.userService.registration(body);
  }
}
