import { inject, Injectable } from '@angular/core';
import {
  CrmLayoutLogoConfig,
  CrmLayoutLogoProvider,
} from 'common-module/layout-v2';
import { map, Observable } from 'rxjs';

import { ClinicsApiService } from '~/api/clinics/clinics-api.service';

@Injectable({ providedIn: 'root' })
export class LogoProvider extends CrmLayoutLogoProvider {
  protected readonly clinicApiService = inject(ClinicsApiService);

  override getComponentConfig(): Observable<CrmLayoutLogoConfig> {
    return this.clinicApiService.getProfileAndLogo().pipe(
      map(({ logoUrl }) => {
        const url = logoUrl ?? '/assets/images/zook-logo.svg';
        return { defaultLogoPath: url, smallLogoPath: url };
      })
    );
  }
}
