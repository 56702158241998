import { Component, HostBinding } from '@angular/core';

import { randomNumberInclusive } from '~/shared/utils/number/random-number';

@Component({
  standalone: true,
  selector: 'app-auth-image',
  template: `
    <img
      [src]="'/assets/images/login/login_' + no + '.png'"
      [alt]="no + '-image'"
    />
  `,
})
export class AuthImageComponent {
  @HostBinding('class') hostClass = 'login-image';

  no = randomNumberInclusive(0, 10);
}
