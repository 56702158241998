import { assertInInjectionContext, inject } from '@angular/core';
import { crmCreateModalFn$ } from 'common-module/modal';
import { CrmTranslateService } from 'common-module/translate';

import { SuccessComponent } from './success.component';

export const openSuccessFn = () => {
  assertInInjectionContext(() => {});

  const modal = crmCreateModalFn$();
  const translate = inject(CrmTranslateService);

  return (title: string, data: SuccessComponent['data']) =>
    modal(SuccessComponent, data, {
      nzTitle: translate.get(title),
      nzMaskClosable: false,
      nzClosable: false,
      nzWidth: '570px',
    });
};
