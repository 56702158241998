import { Routes } from '@angular/router';
import { crmAuthGuard } from 'common-module/auth';
import { CrmLayoutV2OutletComponent } from 'common-module/layout-v2';

import { ResetPasswordProvider } from '~/crm/auth/reset-password/reset-password.provider';

export const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('common-module/auth').then((a) => a.authRoutes),
    providers: [ResetPasswordProvider],
  },
  {
    path: '',
    component: CrmLayoutV2OutletComponent,
    canActivate: [crmAuthGuard()],
    data: { allowedRoles: ['customer'] },
    children: [
      {
        path: '',
        loadComponent: () => import('./features/home/home.component'),
      },
      {
        path: '**',
        redirectTo: '',
      },
    ],
  },
];
