import { UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSizeLDSType } from 'ng-zorro-antd/core/types';

@Component({
  standalone: true,
  selector: 'app-modal-actions',
  template: `
    <button
      nz-button
      nzType="primary"
      [nzSize]="size"
      [nzLoading]="isLoading"
      [disabled]="submitDisabled"
      (click)="submit()"
    >
      {{ submitMessage | crmTranslate | uppercase }}
    </button>
    <button
      nz-button
      nzType="default"
      [nzSize]="size"
      [nzLoading]="isLoading"
      (click)="dismiss()"
    >
      {{ 'generic.close' | crmTranslate | uppercase }}
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NzButtonModule, UpperCasePipe, CrmTranslatePipe],
})
export class ModalActionsComponent {
  @Input() isLoading!: boolean;
  @Input() submitMessage!: string;
  @Input() size: NzSizeLDSType = 'large';
  @Input() submit!: () => void;
  @Input() dismiss!: () => void;
  @Input() submitDisabled = false;
}
