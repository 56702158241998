import { Injectable } from '@angular/core';
import { CrmSafeAny } from 'common-module/core/types';
import { CrmEndpoint, CrmEndpointDecorator } from 'common-module/endpoint';
import { catchError, map, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DocumentsApiService {
  @CrmEndpointDecorator({
    configName: 'crm',
    endpointName: 'documents',
  })
  protected readonly endpoint!: CrmEndpoint<CrmSafeAny>;

  checkResource(id: string) {
    return this.endpoint.request('HEAD', `${id}/resource`).pipe(
      map(() => id),
      catchError(() => of(undefined))
    );
  }
}
