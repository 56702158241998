import { UpperCasePipe } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { CrmModalRefDirective } from 'common-module/modal';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';

import { ExistsData } from './exists.data';

@Component({
  standalone: true,
  selector: 'app-register-exists',
  template: `
    <ng-container *nzModalTitle>
      {{ 'auth.registration.exists.title' | crmTranslate }}
    </ng-container>
    <div class="crm-register-info-icon success">
      <i nz-icon nzType="icons:done" nzTheme="outline"></i>
    </div>
    <div class="crm-register-info-title">
      {{ 'auth.registration.exists.message' | crmTranslate }}
    </div>
    <div class="crm-register-info-text">
      {{ 'auth.registration.exists.text' | crmTranslate }}
    </div>
    <div class="crm-register-info-actions">
      <button
        nz-button
        nzType="primary"
        nzBlock
        nzSize="large"
        (click)="submit({ exists: true })"
      >
        {{ 'auth.actions.resetPassword' | crmTranslate | uppercase }}
      </button>
      <button
        nz-button
        nzType="text"
        nzBlock
        nzSize="large"
        (click)="submit({ exists: false })"
      >
        {{ 'auth.actions.backToLogin' | crmTranslate | uppercase }}
      </button>
    </div>
  `,
  imports: [
    NzModalModule,
    CrmTranslatePipe,
    NzButtonModule,
    NzIconModule,
    UpperCasePipe,
  ],
})
export class ExistsComponent extends CrmModalRefDirective<
  { exists: boolean },
  ExistsData
> {
  @HostBinding('class') protected hostClass = 'crm-register-info';

  constructor() {
    super();
    this.modalRef.updateConfig({ nzMaskClosable: false, nzWidth: '570px' });
  }
}
