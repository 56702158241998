import { UpperCasePipe } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { CrmModalRefDirective } from 'common-module/modal';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';

@Component({
  standalone: true,
  selector: 'app-register-success',
  template: `
    <div class="crm-register-info-icon success">
      <i nz-icon nzType="icons:done"></i>
    </div>
    <div class="crm-register-info-title">
      {{ data.message | crmTranslate }}
    </div>
    <div class="crm-register-info-text">
      {{ data.text | crmTranslate }}
    </div>
    <div class="crm-register-info-actions">
      <button
        nz-button
        nzType="primary"
        nzBlock
        nzSize="large"
        (click)="submit()"
      >
        {{ 'auth.actions.backToLogin' | crmTranslate | uppercase }}
      </button>
    </div>
  `,
  imports: [CrmTranslatePipe, NzButtonModule, NzIconModule, UpperCasePipe],
})
export class SuccessComponent extends CrmModalRefDirective<
  void,
  { message: string; text: string }
> {
  @HostBinding('class') hostClass = 'crm-register-info';
}
